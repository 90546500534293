import React from 'react'
import PropTypes from 'prop-types'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import pic01 from '../images/fotogemma.png'
import pic02 from '../images/dibuixllibre-capcalera.png'
import pic03 from '../images/capcalera-llibre.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Qui soc</h2>
          <span className="image2 main"><img src={pic01} alt="" /></span>
          <p>Em dic Gemma Farràs Prat. Vaig néixer a l’Estany, el 30 de març del 1975. Em vaig llicenciar en Economia i vaig treballar uns quants anys a l’administració local. Sentia la necessitat de descobrir món, el meu propi món, i vaig decidir dir adeu a la professió que exercia i emprendre un viatge fascinant. He fet de la navegació cap a Ítaca el meu objectiu de vida, descobrint el full de ruta, aprenent a portar el timó i gaudint de la força del meu velam. És un viatge amb sentit, que va prenent forma a mesura que avanço, amb tots els canvis de rumb que considero necessaris. <i>Vida sentida</i> és el meu quadern de bitàcola, el far que em guiarà quan em trobi en la foscor.</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Vida Sentida</h2>
          <span className="image2 main"><img src={pic02} alt="" /></span>
          <p><i>Vida sentida</i> explica l'experiència personal de conviure amb un trastorn obsessiu compulsiu. És una experiència vital que permet fer 
          un procés d’autoconeixement immens, descobrir i desenvolupar el propi potencial i transformar el dolor en amor. Qualsevol persona pot retrobar la seva saviesa, 
          siguin quines siguin les limitacions a superar, i recuperar l’equilibri intern per obrir el seu cor a la vida, prendre consciència de qui és i viure plenament.</p>
          <p><center>Quan estimem el que som la nostra essència emplena el món.</center></p>
          <p>
          «Una història sobre l’amor a un mateix, a la vida. Una història de superació personal que passa per la por, la vergonya, la ràbia, la incomprensió, l’alegria, la incertesa, la tristesa, la frustració... però tot i així escrita amb una coherència i humanitat absolutes.» Aloma Cuiné Comas
          </p>
          <p>
          «Hi ha històries que s’escriuen des d’un lloc superior, que són flotadors d’esperança. Si les trobes, et salven la vida perquè expliquen la teva història. És per això que el teu llibre fa falta, és un grandíssim flotador.» Bego García Rodríguez
          </p>
          <p>
          «Aquest nen, el teu llibre, m’ha fet sentir innocent, com un infant agafat de la mà de la seva germana gran. Gemma, t’he d’agrair haver-me mostrat les teves mans, que modelen la teva vida. Gràcies per posar la teva bella espurna de llum al meu pas per aquest camí.» Chicho Sandoval Osorio
          </p>
          <p>
          «Aquest llibre és un cant a la vida i a la nostra divinitat. Llegir-lo ha estat un gran regal i una nova oportunitat per sentir la llum i la força que tots portem a dins.» Cristina Álvarez Vila
          </p>
          <p>
          «Tot el que escrius és aigua fresca i clara que flueix sense esforç del teu cor al cor de tots aquells que vibren i volen vibrar en l’amor. És una trucada directa a l’essència de llum que som.» Dolors Beltran Boixadera
          </p>
          <p>
          «Aquest llibre no és només la història d’algú que ha patit un trastorn mental. És la història de cada un de nosaltres, en la qual queda palès que la nostra fragilitat pot ser transformada en la forta-lesa que ens fa créixer i ser millors persones.» Dra. Emma Carné
          </p>
          <p>
          «Gràcies, Gemma, aquest llibre és la prova....
          Quan la dualitat de la ment es rendeix a la força del Cor, amb entrega total, s’esdevenen els petits grans miracles!
          Un regal de llibre.» Laura Mayoral Arnay
          <p>
          </p>
          «Tens un do per escriure i, a més, tens una història i una trajectòria vital brutal. I al llibre es respira. Tanques un cicle, obres una nova etapa i segueixes caminant, sabent que has fet un cim, assaborint la fita i albirant nous horitzons. Essent conscient que allò essencial per viure és dins teu i de tots els que compartim aquest viatge apassionant.» Lurdes Castells Castany
          </p>
          <p>
          «Testimoni de superació-transformació. Escrit des del cor, per arribar al cor.» Gemma León Roig
          </p>
          <p>
          «Un llibre que no et deixa indiferent. I no només per la teva experiència amb el TOC i l’enfocament que ofereixes dels trastorns mentals en general, sinó perquè aquest és, per sobre de tot, un llibre de Vida, de Valentia, d’Amor Incondicional, d’Inconformisme, d’Esperança, d’Aprenentatge, de Lluita. Un immens regal per a tots. Gràcies.» Marta Paixau Puigoriol
          </p>
          <p>
          «Et felicito per aquest fill que has engendrat... des de la foscor més profunda... i essent el seu naixement pura llum per a tothom que el pugui acariciar...» Núria Trullà Roqueta
          </p>
          <p>
          «Llegir-te implica llegir-me, em sembla que aquest llibre només es pot llegir així.» Queralt Bancells Farràs
          </p>
          <p>
          «Un llibre ple d’esperança i amor. Un llibre ple de tu, ple de jo, ple de tots. Gràcies infinites. T’estimo.» Roser Farràs Prat
          </p>
          <p>
          «Caminar sense aturar-se, endins, amb la freda nuesa del cos sobre les pedres, aturar-se un moment, aturar-me, respirar-me com qui sanglota als marges. Deixar-se captivar, deixar-me acostar als rius per on he de travessar la lluna blanca. I sentir per un instant l’ésser que soc i en qui vull seguir endinsant-me. Caminar, com qui travessa un desert ple de jo, de vida, d’esperança. Sentir, sense parar, carícies blanques.» Xavier Rivero Tarruella
          </p>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">El llibre</h2>
          <span className="image main"><OutboundLink href="https://bit.ly/vidasentida" target="_blank"><img src={pic03} alt="Vida Sentida" /></OutboundLink></span>
          <p>Pots comprar el llibre a Amazon en format paper o ebook (Kindle) clicant a la imatge de sobre o al següent enllaç: &nbsp;&nbsp;
            <OutboundLink href="https://bit.ly/vidasentida" target="_blank">Vida sentida a amazon.es</OutboundLink>
          </p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contacte</h2>
          <form name="contact_ca" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Nom</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Missatge</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Envia missatge" className="special" /></li>
              <li><input type="reset" value="Neteja" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
           </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main