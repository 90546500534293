import React from 'react'
import { Link } from 'gatsby'

const Lang = ({ lang, timeout }) => {
  const idioma = lang === 'ca' ? 'Español' : 'Català'
  const link = lang === 'ca' ? '/es/' : '/'
  return (
    <div style={timeout ? {display: 'none'} : {}}>
      <span className="icon-lang fa-language">
        <Link to={link}>
          <span className="label">&nbsp;{idioma}</span>
        </Link>
      </span>
    </div>
  )
}

export default Lang
